import React from 'react';

function BuyMeACoffee() {
  return (
    <div>
      <a href="https://www.buymeacoffee.com/sumitpal29" target="_blank">
        <img
          src="https://i.imgur.com/ENn4Wuo.png"
          alt="Buy Me A Coffee"
          style={{ height: '60px', width: '217px' }}
        />
      </a>
    </div>
  );
}

export default BuyMeACoffee;
