import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { Tag, Divider } from 'antd';
import cx from 'classnames';

import Layout from '../components/Layout';
import AssociateLinks from '../components/AssociateLinks';
import MarkDownRenderer from '../components/MarkDownRenderer';
import BuyMeACoffee from '../components/BuyMeACoffee';
import { EMPTY_STRING } from '../constants/base';

import { contentContainer } from '../scss/content.module.scss';
import { contentH1 } from '../scss/content.module.scss';

function Posts({ data }) {
  const markdownContent = _get(data, 'sanityPost.content', '');
  const imageUrl = _get(data, 'sanityPost.imageUrl', '');
  const postTitle = _get(data, 'sanityPost.title', '');
  const postDescription = _get(data, 'sanityPost.description', '');
  const externalLinks = _get(data, 'sanityPost.externalLinks', []);
  const tags = _get(data, 'sanityPost.categories', []);
  const publishedAt = _get(data, 'sanityPost.publishedAt', null);
  const slug = _get(data, 'sanityPost.slug.current', EMPTY_STRING);
  const url = `https://www.sumitpal.in/blog-post/${slug}`;
  const title = `${postTitle} - Sumit Pal`;

  const seo = {
    title,
    description: postDescription,
    canonical: url,
    openGraph: {
      url: url,
      title,
      description: postDescription,
      images: [
        {
          url: imageUrl,
          width: 1600,
          height: 400,
          alt: postTitle,
        },
      ],
      site_name: 'sumitpal.in',
    },
  };

  return (
    <Layout seoData={seo}>
      <div className={contentContainer}>
        <img style={{ width: '100%', marginBottom: '2rem' }} src={imageUrl} />
        <div className="post-info flex flex-space-between">
          <div className="badges">
            {!_isEmpty(tags) &&
              _map(tags, (tag) => (
                <Tag
                  key={_get(tag, 'title', '')}
                  color={_get(tag, 'colorHexCode', '#ccc')}
                >
                  {_get(tag, 'title', '')}
                </Tag>
              ))}
          </div>
          <div className="post-time">
            Published {moment(publishedAt).fromNow()}
          </div>
        </div>
        <h1 className={cx('m-b-24 p-t-30', contentH1)}>{postTitle}</h1>
        <MarkDownRenderer content={markdownContent} />
        <Divider />
        <BuyMeACoffee />
        <Divider />
        {!_isEmpty(externalLinks) && (
          <div>
            <h2 className="m-b-30 p-t-48">Related items are available here</h2>
            <AssociateLinks links={externalLinks} />
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Posts;

export const query = graphql`
  query ($slug: String) {
    sanityPost(slug: { current: { eq: $slug } }) {
      title
      content
      slug {
        current
      }
      externalLinks {
        title
        link
        description
        imageUrl
      }
      publishedAt
      categories {
        title
        colorHexCode
      }
      imageUrl
    }
  }
`;

Posts.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};
